var render, staticRenderFns
import script from "./feedback-carousel.vue?vue&type=script&lang=js&"
export * from "./feedback-carousel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../var/task/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/task/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('48a6adfe')) {
      api.createRecord('48a6adfe', component.options)
    } else {
      api.reload('48a6adfe', component.options)
    }
    
  }
}
component.options.__file = "tmp/tmp-8O60l0Y2mvyh1/JS/comp/feedback-carousel.vue"
export default component.exports